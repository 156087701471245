const DashboardStore = {
    state: {
        detailDashboard: {},
    },
    mutations: {
        set_detail_dashboard(state, data) {
            state.detailDashboard = data
        },        
    },
    getters: {
        detailDashboard: state => {
            return state.detailDashboard
        }       
    }
}
export default DashboardStore
