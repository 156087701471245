const strukPesanan = {
    state: {
        warehouseID: '',
        id: '',
        isLogoShow: false,
        isBusinessShow: false,
        businessName: '',
        warehouseName: '',
        phone: '',
        address: '',
        isWarehouseShow: false,
        isPhoneShow: false,
        isAddressShow: false,
        isHeaderShow: false,
        isFooterShow: false,
        isQollega: false,
        footer: '',
        logo: null,
        logoPreview: '',
        isNoAntrian: false
    },
    mutations: {
        set_id_pesanan(state, data) {
            state.id = data
        },
        set_warehouse_id_pesanan(state, data) {
            state.warehouseID = data
        },
        set_logo_pesanan(state, data) {
            state.isLogoShow = data
        },
        set_logo_image_pesanan(state, data) {
            state.logo = data
        },
        set_logo_preview_pesanan(state, data) {
            state.logoPreview = data
        },
        set_business_pesanan(state, data) {
            state.isBusinessShow = data
        },
        set_business_name_pesanan(state, data) {
            state.businessName = data
        },
        set_warehouse_pesanan(state, data) {
            state.isWarehouseShow = data
        },
        set_warehouse_name_pesanan(state, data) {
            state.warehouseName = data
        },
        set_address_pesanan(state, data) {
            state.isAddressShow = data
        },
        set_address_name_pesanan(state, data) {
            state.address = data
        },
        set_phone_pesanan(state, data) {
            state.isPhoneShow = data
        },
        set_phone_number_pesanan(state, data) {
            state.phone = data
        },
        set_header_pesanan(state, data) {
            state.isHeaderShow = data
        },
        set_footer_pesanan(state, data) {
            state.isFooterShow = data
        },
        set_footer_text_pesanan(state, data) {
            state.footer = data
        },
        set_is_qollega_pesanan(state, data) {
            state.isQollega = data
        },
        set_is_no_antrian_pesanan(state, data) {
            state.isNoAntrian = data
        }
    },
    getters: {
        get_id_pesanan: state => {
            return state.id
        },
        get_warehouse_id_pesanan: state => {
            return state.warehouseID
        },
        get_logo_pesanan: state => {
            return state.isLogoShow
        },
        get_logo_image_pesanan: state => {
            return state.logo
        },
        get_logo_preview_pesanan: state => {
            return state.logoPreview
        },
        get_business_pesanan: state => {
            return state.isBusinessShow
        },
        get_business_name_pesanan: state => {
            return state.businessName
        },
        get_warehouse_pesanan: state => {
            return state.isWarehouseShow
        },
        get_warehouse_name_pesanan: state => {
            return state.warehouseName
        },
        get_address_pesanan: state => {
            return state.isAddressShow
        },
        get_address_name_pesanan: state => {
            return state.address
        },
        get_phone_pesanan: state => {
            return state.isPhoneShow
        },
        get_phone_number_pesanan: state => {
            return state.phone
        },
        get_header_pesanan: state => {
            return state.isHeaderShow
        },
        get_footer_pesanan: state => {
            return state.isFooterShow
        },
        get_footer_text_pesanan: state => {
            return state.footer
        },
        get_is_qollega_pesanan: state => {
            return state.isQollega
        },
        get_is_no_antrian_pesanan: state => {
            return state.isNoAntrian
        }
    }
}

export default strukPesanan
