import Vue from 'vue';
import Vuex from 'vuex';
import strukPembayaran from '@/index/pages/settings/tabs/struk/store/pembayaran.js';
import strukPesanan from '@/index/pages/settings/tabs/struk/store/pesanan.js';
import POSStore from '@/pos/store';
import productStore from '@/index/pages/product/product_list/store';
import DashboardStore from '@/index/pages/dashboard/store';
import stokOpnameStore from '@/index/pages/inventory/tabs/stock_opname/store';
import PaymentMethodStore from '@/index/pages/settings/tabs/metode_pembayaran/store';
import tableManagementStore from '@/index/pages/table_management/store';
import QrisStore from '@/qris_activation/store';

Vue.use(Vuex);

// ** Global store
const store = {
  state: {
    userData: {},
    searchQuery: '',
    warehouses: [],
    subscriptionData: {},
    invoiceData: {},
  },
  mutations: {
    set_user_data(state, data) {
      state.userData = data;
    },
    set_search_query(state, data) {
      state.searchQuery = data;
    },
    set_list_warehouses(state, data) {
      state.warehouses = data;
    },
    set_subscription_data(state, data) {
      state.subscriptionData = data;
    },
    set_invoice_data(state, data) {
      state.invoiceData = data;
    }
  },
  getters: {
    get_user_data: (state) => {
      return state.userData;
    },
    get_search_query: (state) => {
      return state.searchQuery;
    },
    get_list_warehouses: (state) => {
      return state.warehouses;
    },
    get_subscription_data: (state) => {
      return state.subscriptionData;
    },
    get_invoice_data: (state) => {
      return state.invoiceData;
    }
  },
};

export default new Vuex.Store({
  modules: {
    store,
    strukPembayaran,
    strukPesanan,
    POSStore,
    productStore,
    DashboardStore,
    stokOpnameStore,
    PaymentMethodStore,
    tableManagementStore,
    QrisStore,
  },
});
