const stokOpnameStore = {
    state: {
        elements_opname: {}
    },
    mutations: {
        set_elements_opname(state, data) {
            state.elements_opname = data
        }
    },
    getters: {
        elements_opname: state => {
            return state.elements_opname
        }
    }
}
export default stokOpnameStore
