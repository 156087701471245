const productStore = {
  state: {
    elements_product: {},
  },
  mutations: {
    set_elements_product(state, data) {
      state.elements_product = data;
    },
  },
  getters: {
    elements_product: (state) => {
      return state.elements_product;
    },
  },
};
export default productStore;
