<template>
  <div class="offline-wrapper">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 mt-4">
        <div class="text-center">
          <img :src="offlineImage" class="img-fluid" />
          <button type="button" class="btn btn-primary my-3" @click="reload">
            <i class="feather icon-refresh-cw me-2"></i>Muat Ulang
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OfflineImage from '@/assets/images/error/offline.png';

export default {
  name: 'OfflineComponent',
  data: function () {
    return {
      offlineImage: OfflineImage,
    };
  },
  methods: {
    reload() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .offline-wrapper {
    margin-top: 10px;
    height: calc(100vh - 66px - 70px);
    background: white;
  }
}
</style>
