import axios from 'axios';

const PaymentMethodStore = {
  state: {
    details: {},
    mdr: 0,
  },
  mutations: {
    fetch_detail_transaction(state, id) {
      axios
        .get(`${process.env.VUE_APP_API_URL}qris-menu/detail-transaksi/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((req) => {
          const res = req.data;

          let details = {
            id: res.id,
            referenceNum: res.reff,
            type: res.tipe.charAt(0).toUpperCase() + res.tipe.slice(1),
            status: res.status.charAt(0).toUpperCase() + res.status.slice(1),
            bankName: res.nama_bank,
            accountNumber: res.no_rekening,
            note: res.keterangan,
            mdr: res.mdr,
            adminFee: res.biaya_admin,
            transactionNum: res.no_transaksi,
            total: res.total,
            date: res.waktu,
          };

          state.details = details;
        });
    },
    set_mdr(state, payload) {
      state.mdr = payload;
    },
  },
  getters: {
    details: (state) => {
      return state.details;
    },
    mdr: (state) => {
      return state.mdr;
    },
  },
};
export default PaymentMethodStore;
